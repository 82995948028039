<template>
  <div class="h-full print:w-full">
    <Portal to="title">
      {{ $t('order_header.order_label') }}
    </Portal>
    <Portal to="header">
      <div class="flex items-center">
        <button :disabled="savingOrder" class="button button-primary button-lg button-filled flex items-center -mt-2"
          :class="{
            'hover:opacity-75 focus:opacity-75': !savingOrder,
            'opacity-50 cursor-not-allowed': savingOrder,
          }" @click="saveOrder">
          <IconRefresh :class="{ 'animation-spin': savingOrder }" class="icon-save h-26 w-26 mr-8" />
          {{ $t('generic_crud_labels.save') }}
        </button>
      </div>
    </Portal>
    <OrderForm ref="order-form" :human-readable-id="humanReadableId" :location="orderLocation" :tooltips="allTooltips"
      :id="id" :user-details="userDetails" :order-saved="orderSaved" @add="addOrder($event)" />
  </div>
</template>

<script>
import { FBModule, FBStorageRef } from '@/helpers/firebaseServices';
import {
  ordersPath, getLocations, getTooltips, getUserOrders, getUserDetails,
} from '@/helpers/firestoreRefs';
import { getUserProfilesPath } from '@/helpers/storageRefs';
import OrderForm from '@/components/order/OrderForm';
import IconRefresh from '@/components/icon/IconRefresh';
import UILocationsModule from '@/store/modules/UI/UILocations';
import UITooltipsModule from '@/store/modules/UI/UITooltips';
import userOrdersModule from '@/store/modules/user/userOrders';

export default {
  components: {
    OrderForm,
    IconRefresh,
  },
  data: () => ({
    savingOrder: false,
    orderSaved: false,
    userDetails: null,
    id: '',
    UILocations: {
      unsubscribe: null,
      replaceState: true,
    },
    UITooltips: {
      unsubscribe: null,
      replaceState: true,
    },
    lastUserOrder: {
      unsubscribe: null,
      humanReadableId: '',
    },
  }),
  async created() {
    if (!this.$store.state?.UI?.locations) this.$store.registerModule(['UI', 'locations'], UILocationsModule);
    if (!this.$store.state?.UI?.tooltips) this.$store.registerModule(['UI', 'tooltips'], UITooltipsModule);
    if (!this.$store.state.user?.orders) this.$store.registerModule(['user', 'orders'], userOrdersModule);

    await getUserDetails(this.$store.state.user.auth.id)
      .get()
      .then(async (userSnapshot) => {
        this.userDetails = {
          id: userSnapshot.id,
          ...userSnapshot.data(),
        };
      })
      .catch((error) => {
        console.error(error);
        this.order.loadingStatus = 'error';
      });

    this.UILocations.unsubscribe = await getLocations()
      .onSnapshot((snapshot) => {
        this.$store.dispatch('UI/locations/onLocationsSnapshot', {
          snapshot,
          replaceState: this.UILocations.replaceState,
        });

        // Don't replace the cache on subsequent snapshots
        this.UILocations.replaceState = false;
      }, (error) => {
        console.error(error);
      });

    this.UITooltips.unsubscribe = await getTooltips()
      .onSnapshot((snapshot) => {
        this.$store.dispatch('UI/tooltips/onTooltipsSnapshot', {
          snapshot,
          replaceState: this.UITooltips.replaceState,
        });

        // Don't replace the cache on subsequent snapshots
        this.UITooltips.replaceState = false;
      }, (error) => {
        console.error(error);
      });

    // Pass context to the next method
    const self = this;
    // Getting the last order of this user
    this.lastUserOrder.unsubscribe = await getUserOrders(this.userAuthId)
      .orderBy('humanReadableId', 'asc')
      .onSnapshot((snapshot) => {
        if (!snapshot.docs[0]) {
          this.saveOrder();
          return;
        }
        const orders = snapshot.docs;

        orders.sort((prevOrder, nextOrder) => {
          const prevOrderNumber = parseInt(prevOrder.data().humanReadableId.split('-')[1], 10);
          const nextOrderNumber = parseInt(nextOrder.data().humanReadableId.split('-')[1], 10);

          return prevOrderNumber - nextOrderNumber;
        });

        // Get orders humanReadableId with digital ending
        const filteredOrders = orders.filter((order) => {
          const idParts = order.data().humanReadableId.split('-');
          const idPartsNum = idParts[idParts.length - 1];
          // const idPartsKey = idParts[0];
          if (Number.isInteger(+idPartsNum)) return order;
        });

        const lastOrderIndex = filteredOrders.length ? filteredOrders.length - 1 : orders.length - 1;
        const idParts = filteredOrders.length ? filteredOrders[lastOrderIndex].data().humanReadableId.split('-') : orders[lastOrderIndex].data().humanReadableId.split('-');
        let idPartsNum = idParts[idParts.length - 1];
        const idPartsKey = idParts[0];
        if (idPartsNum === 'F') idPartsNum = 0;

        self.lastUserOrder.humanReadableId = `${idPartsKey}-${parseInt(idPartsNum, 10) + 1}`;

        this.saveOrder();
      }, (error) => {
        console.error(error);
      });
  },
  computed: {
    orderLocation() {
      console.log('In orderLocation', this.userLocationId, 'Locations',
        this.$store.state.UI?.locations.all);
      if (typeof this.$store.state.UI?.locations.all[this.userLocationId] === 'undefined') {
        //TODO: we must find out why this is undefined!
        return {};
      }
      return { ...this.$store.state.UI?.locations.all[this.userLocationId] };
    },
    userLocationId() {
      if (typeof this.$store.state.user.details.locations[0] === 'undefined') {
        return null;
      } else {
        return this.$store.state.user.details.locations[0];
      }
    },
    allTooltips() {
      if (!this.$store.state.UI?.tooltips) return null;
      return this.$store.state.UI.tooltips.all;
    },
    userAuthId() {
      return this.$store.state.user.auth.id;
    },
    humanReadableId() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      if (this.$store.state.user?.details.initialLoading || !this.lastUserOrder.humanReadableId) this.lastUserOrder.humanReadableId = `${this.$store.state.user.details.humanReadableIdKey}-1`;

      return this.lastUserOrder.humanReadableId;
    },
  },
  methods: {
    saveOrder() {
      this.$refs['order-form'].submitForm();
    },
    async addOrder(order) {
      this.savingOrder = true;

      // Uploading pictures
      const imageUploads = [];
      const imageUploadPaths = {};

      try {
        order.metalComponents.forEach((metalComponent, componentIndex) => {
          if (metalComponent.imageFile) {
            const imageFileStoragePath = `${getUserProfilesPath(this.userAuthId)}/${metalComponent.imageFile.name}`;
            const imageUpload = FBStorageRef
              .child(imageFileStoragePath)
              .put(metalComponent.imageFile)
              .then(() => {
                imageUploadPaths[componentIndex] = imageFileStoragePath;
              });
            imageUploads.push(imageUpload);
            // eslint-disable-next-line no-param-reassign
            delete metalComponent.imageFile;
          }
        });

        await Promise.all(imageUploads);
      } catch (error) {
        // Error during image upload
        console.error(error);
        this.savingOrder = false;
        return;
      }


      const { header: headerOptions, metalComponents, moreOptions } = order;
      const nowTimestamp = FBModule.firestore.Timestamp.fromDate(new Date());

      const cleanOrderFields = {
        // Header
        ...headerOptions.billingAddress && { billingAddress: headerOptions.billingAddress },
        ...headerOptions.shippingAddress && { shippingAddress: headerOptions.shippingAddress },
        ...headerOptions.companyName && { companyName: headerOptions.companyName },
        ...headerOptions.phoneNumberForQuestions && { phoneNumberForQuestions: headerOptions.phoneNumberForQuestions },
        ...headerOptions.offerNumber && { offerNumber: headerOptions.offerNumber },
        ...headerOptions.deliveryDate && { deliveryDate: headerOptions.deliveryDate },
        ...headerOptions.pickupDate && { pickupDate: headerOptions.pickupDate },

        orderDate: nowTimestamp,
        ...headerOptions.orderNumber && { orderNumber: headerOptions.orderNumber },
        ...headerOptions.commission && { commission: headerOptions.commission },
        ...headerOptions.deliverySlipNumber && { deliverySlipNumber: headerOptions.deliverySlipNumber },
      };

      if (typeof this.userDetails.billingAddress !== 'undefined') {
        cleanOrderFields.billingAddress = this.userDetails.billingAddress
      }
      if (typeof this.userDetails.shippingAddress !== 'undefined') {
        cleanOrderFields.shippingAddress = this.userDetails.shippingAddress
      }

      // Metal components
      const cleanMetalComponents = [];
      metalComponents.forEach((metalComponent, componentIndex) => {
        // Metal component - Profile types
        const profilesWithDimensions = [];
        metalComponent.profilesWithDimensions.forEach((profileWithDimensions) => {
          if (profileWithDimensions.type || profileWithDimensions.dimensions) {
            profilesWithDimensions.push({ ...profileWithDimensions });
          }
        });

        // Metal component
        const cleanMetalComponent = {
          ...metalComponent.imageURL && { imageURL: metalComponent.imageURL },
          ...metalComponent.imageFile && { imageFile: metalComponent.imageFile },
          ...metalComponent.id && { id: metalComponent.id },
          ...metalComponent.clearRowIsVisible && { clearRowIsVisible: metalComponent.clearRowIsVisible },
          ...metalComponent.materialDesignation && { materialDesignation: metalComponent.materialDesignation },
          ...metalComponent.position && { position: metalComponent.position },
          ...metalComponent.count && { count: metalComponent.count },
          ...imageUploadPaths[componentIndex] && { imageStoragePath: imageUploadPaths[componentIndex] },
          ...profilesWithDimensions.length && { profilesWithDimensions },
          ...metalComponent.length && { length: metalComponent.length },
          ...metalComponent.width && { width: metalComponent.width },
          ...metalComponent.depth && { depth: metalComponent.depth },
        };

        if (Object.keys(cleanMetalComponent).length) {
          cleanMetalComponents.push(cleanMetalComponent);
        }
      });

      if (cleanMetalComponents.length) cleanOrderFields.metalComponents = cleanMetalComponents;

      // More options
      // More options - Treatment
      if (Object.keys(moreOptions.treatment).length) {
        const { treatment: treatmentOptions } = moreOptions;

        cleanOrderFields.treatment = {
          ...treatmentOptions.hotDipGalvanizingISO1461 && { hotDipGalvanizingISO1461: true },
          ...treatmentOptions.DASt022TrustZone?.length && { DASt022TrustZone: treatmentOptions.DASt022TrustZone },
          ...treatmentOptions.onlyPickleZincAndBackBlack && { onlyPickleZincAndBackBlack: true },
          ...treatmentOptions.duroplexCoating && { duroplexCoating: true },
          ...treatmentOptions.thermoplexCoating && { thermoplexCoating: true },
          ...treatmentOptions.duroThermoShade && { duroThermoShade: treatmentOptions.duroThermoShade },
          ...treatmentOptions.SPlusZ && { SPlusZ: true },
          ...treatmentOptions.ferrostyleCoatingN && { ferrostyleCoatingN: true },
          ...treatmentOptions.ferrostyleCoatingP && { ferrostyleCoatingP: true },
          ...treatmentOptions.ferrostyleShade && { ferrostyleShade: treatmentOptions.ferrostyleShade },
          ...treatmentOptions.indoorApplication && { indoorApplication: true },
          ...treatmentOptions.outdoorApplication && { outdoorApplication: true },
          ...treatmentOptions.galvanicZincPlating && { galvanicZincPlating: true },
          ...treatmentOptions.brightChromePlating && { brightChromePlating: true },
          ...treatmentOptions.blueChromating && { blueChromating: true },
          ...treatmentOptions.EN1090 && { EN1090: true },
          // eslint-disable-next-line max-len
          ...treatmentOptions.corrosivityCategory?.length && { corrosivityCategory: treatmentOptions.corrosivityCategory },
          ...treatmentOptions.preparationDegree?.length && { preparationDegree: treatmentOptions.preparationDegree },
          ...treatmentOptions.preparationDegreeTest?.length && { preparationDegreeTest: treatmentOptions.preparationDegreeTest },
        };

        if (!Object.keys(cleanOrderFields.treatment).length) delete cleanOrderFields.treatment;
      }

      // More options - Special
      if (Object.keys(moreOptions.special).length) {
        const { special: specialOptions } = moreOptions;

        cleanOrderFields.special = {
          ...specialOptions.cleanThread && { cleanThread: true },
          ...specialOptions.cleanThreadCount && { cleanThreadCount: specialOptions.cleanThreadCount },
          ...specialOptions.QS && { QS: true },
          ...specialOptions.measurementProtocol && { measurementProtocol: true },
          ...specialOptions.measurementProtocolAdvanced && { measurementProtocolAdvanced: true },
          ...specialOptions.drilledInternalHoles && { drilledInternalHoles: true },
          ...specialOptions.closeHoles && { closeHoles: true },
          ...specialOptions.noAdditionallyDrilledHoles && { noAdditionallyDrilledHoles: true },
          ...specialOptions.removeZincOnly && { removeZincOnly: true },
          ...specialOptions.burnOffPaintOnly && { burnOffPaintOnly: true },
          ...specialOptions.removePaintAndZinc && { removePaintAndZinc: true },
          ...specialOptions.euroPalletsCount && { euroPalletsCount: specialOptions.euroPalletsCount },
          ...specialOptions.palletFramesCount && { palletFramesCount: specialOptions.palletFramesCount },
          ...specialOptions.halfPalletFramesCount && { halfPalletFramesCount: specialOptions.halfPalletFramesCount },
          ...specialOptions.disposablePalletsCount && { disposablePalletsCount: specialOptions.disposablePalletsCount },
          ...specialOptions.otherDescription && { otherDescription: specialOptions.otherDescription },
        };

        if (!Object.keys(cleanOrderFields.special).length) delete cleanOrderFields.special;
      }

      // More options - Transport delivery
      if (Object.keys(moreOptions.transportDelivery).length) {
        const { transportDelivery: transportDeliveryOptions } = moreOptions;

        cleanOrderFields.transportDelivery = {
          ...transportDeliveryOptions.byGalvaswissLorry && { byGalvaswissLorry: true },
          ...transportDeliveryOptions.pickUpByHimself && { pickUpByHimself: true },
        };

        if (!Object.keys(cleanOrderFields.transportDelivery).length) delete cleanOrderFields.transportDelivery;
      }

      // More options - Transport return delivery
      if (Object.keys(moreOptions.transportReturnDelivery).length) {
        const { transportReturnDelivery: transportReturnDeliveryOptions } = moreOptions;

        cleanOrderFields.transportReturnDelivery = {
          ...transportReturnDeliveryOptions.byGalvaswissLorry && { byGalvaswissLorry: true },
          ...transportReturnDeliveryOptions.pickUpByHimself && { pickUpByHimself: true },
        };

        if (!Object.keys(cleanOrderFields.transportReturnDelivery).length) {
          delete cleanOrderFields.transportReturnDelivery;
        }
      }

      /*
      # Doc spec
      humanReadableId: String (Ex.: metallbau HEIMSCH GmbH: HEM-0001)
      status: Number (0: active status, 1: marked as processed by ERP)
      location: Document fields (Document ref)
      */
      let location = this.orderLocation;
      if (typeof location.id === 'undefined') {
        try {
          const locationDoc = await getLocations().doc(this.userLocationId).get();
          location = { ...locationDoc.data(), ...{ id: locationDoc.id } };
        } catch (locatonGetError) {
          console.error(locatonGetError);
          location = {};
        }
      }

      const orderDoc = {
        // Doc meta
        createdTime: new Date(),
        modifiedTime: new Date(),
        createdBy: this.userAuthId,
        humanReadableId: this.humanReadableId || 'GAS-F',
        status: 0,
        location,
        ...cleanOrderFields,
      };

      this.lastUserOrder.unsubscribe();

      console.log('Location in OrderAdd', this.orderLocation);

      ordersPath.add(orderDoc)
        .then((docRef) => {
          console.log('Document written with ID OrderAdd: ', docRef.id);
          this.id = docRef.id;
          this.savingOrder = false;
          this.orderSaved = true;

          this.$store.dispatch('user/orders/onOrderSnapshot', {
            id: docRef.id,
            ...orderDoc,
          });
        })
        .catch((error) => {
          this.savingOrder = false;
          console.error(error);
        });
    },
  },
  beforeDestroy() {
    if (this.UILocations.unsubscribe !== null) this.UILocations.unsubscribe();
    if (this.UITooltips.unsubscribe !== null) this.UITooltips.unsubscribe();
    if (this.lastUserOrder.unsubscribe !== null) this.lastUserOrder.unsubscribe();
  },
};
</script>

<style scoped>
.icon-save .primary {
  @apply fill-transparent;
}
</style>
