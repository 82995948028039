import Vue from 'vue';


export default {
  namespaced: true,
  state: {
    loadingStatus: 'init',
    all: {},
  },
  getters: {},
  mutations: {
    RESET_STATE(state) {
      state.loadingStatus = 'init';
      state.all = {};
    },
    SET_LOADING_STATUS(state, loadingStatus) {
      state.loadingStatus = loadingStatus;
    },
    SET_LOCATION(state, { id, ...data }) {
      const locationData = { ...data };

      // Read-only id property
      Object.defineProperty(locationData, 'id', {
        value: id,
        enumerable: true,
      });
      Vue.set(state.all, id, locationData);
    },
    REMOVE_LOCATION(state, id) {
      Vue.delete(state.all, id);
    },
  },
  actions: {
    async resetState({ commit }) {
      commit('RESET_STATE');

      return Promise.resolve();
    },
    onLocationsSnapshot({ state, commit }, { snapshot, replaceState = false }) {
      let docIdsToRemove = []; // Documents to remove
      if (replaceState) docIdsToRemove = Object.keys(state.all);

      // Set loading status
      if (state.loadingStatus === 'init') commit('SET_LOADING_STATUS', 'init-loading');
      else commit('SET_LOADING_STATUS', 'snapshot-loading');

      snapshot.docChanges().forEach((snapshotChange) => {
        if (snapshotChange.type === 'added' || snapshotChange.type === 'modified') {
          commit('SET_LOCATION', {
            id: snapshotChange.doc.id,
            ...snapshotChange.doc.data(),
          });

          if (replaceState) docIdsToRemove = docIdsToRemove.filter((docId) => docId !== snapshotChange.doc.id);
        } else if (snapshotChange.type === 'removed') {
          commit('REMOVE_LOCATION', snapshotChange.doc.id);
        }
      });

      // Cleanup existing state
      docIdsToRemove.forEach((docIdToRemove) => commit('REMOVE_TEAM_MEMBER', docIdToRemove));

      // Set loading status
      if (state.loadingStatus === 'init-loading') commit('SET_LOADING_STATUS', 'init-loaded');
      else commit('SET_LOADING_STATUS', 'snapshot-loaded');
    },
  },
};
